import { all } from "redux-saga/effects";

//public

import SchoolSaga from "./model/school/saga";



export default function* rootSaga() {
  yield all([
    //public
    
    SchoolSaga(),
    
    
  ]);
}

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import React, { useEffect, useState } from "react";

//Image Cropper
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
//Import Image
import src from "../../assets/images/users/user.png";

import Parse from "parse";

const UpdatePhoto = (props) => {
  const [preview, setPreview] = useState("");
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [cropResult, setCropResult] = useState("");
  const [shouldShowDoneButton, setShouldShowDoneButton] = useState(false);
  const [cropper, setCropper] = useState("");
  const [buttonTitle, setButtonTitle] = useState(props.buttonTitle ?? "Upload");
  const [buttonTitleInitial, setButtonTitleInitial] = useState(
    props.buttonTitleInitial ?? "Upload"
  );

  const [fileName, setFileName] = useState(
    props.imageFileName != undefined || props.imageFileName != ""
      ? props.imageFileName
      : "pic.jpg"
  );
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     preview: this.props.initialImage ?? "",
  //     shouldShowChangedImage: false,
  //     model: this.props.isPopupOn,
  //     src,
  //     cropResult: null,
  //     shouldShowDoneButton: false,

  //   };

  //   this.handleDoneButton = this.handleDoneButton.bind(this);

  // }

  // getBase64(file, cb) {
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     var resultWithName = { name: file.name, result: reader.result };
  //     cb(resultWithName);
  //   };
  //   reader.onerror = function (error) {
  //     console.log("Error: ", error);
  //   };
  // }

  useEffect(() => {
    if (props.initialImage) {
      setPreview(props.initialImage);
      setShouldShowDoneButton(true);
    } else {
      setPreview("");
      setShouldShowDoneButton(false);
    }
  }, [props.initialImage]);

  const togglePopup = () => {
    //setState({ model: !model });
    setIsModelOpen(!isModelOpen);
  };

  const displayButton = () => {
    const inputStyle = {
      width: 0.1,
      height: 0.1,
      opacity: 0,
      overflow: "hidden",
      position: "absolute",
      zIndex: -1,
    };
    if (preview === "") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Label
            htmlFor={`inputImageUpload ${props.tempId ?? ""}`}
            color="primary"
            className="btn btn-primary"
          >
            {buttonTitleInitial}
            <input
              id={`inputImageUpload ${props.tempId ?? ""}`}
              style={inputStyle}
              type="file"
              onChange={onChange}
              accept="image/*"
            />
          </Label>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          color="primary"
          className="waves-effect waves-light center sm"
          onClick={togglePopup}
        >
          {buttonTitle}
        </Button>
      </div>
    );
  };

  const onChange = (e) => {
    e.preventDefault();
    setFileName(e.target.files[0].name);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      //setState({ src: reader.result, shouldShowDoneButton: true });
      setPreview(reader.result);
      setShouldShowDoneButton(true);
      if (!isModelOpen) {
        setIsModelOpen(true);
      }
    };
    reader.readAsDataURL(files[0]);
  };

  const handleDoneButton = () => {
    console.log("work");

    var cr = cropImage();

    let tempName = fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');

    var imageFile = new Parse.File(tempName, {
      base64: cr,
    });

    togglePopup();
    props.onPicChange(imageFile, cr);
  };

  const cropImage = () => {
    if (typeof cropper.getCroppedCanvas() === "undefined") {
      return;
    }

    setCropResult(cropper.getCroppedCanvas().toDataURL());
    setShouldShowDoneButton(true);
    return cropper.getCroppedCanvas().toDataURL();
  };

  return (
    <div>
      {displayButton()}

      <Modal isOpen={isModelOpen} fade={false}>
        <ModalHeader toggle={togglePopup}>Change Picture</ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Cropper
              style={{ height: 400, width: "100%" }}
              aspectRatio={1 / 1}
              preview=".img-preview"
              guides={true}
              src={preview === "" ? src : preview}
              ref={(cropper) => {
                setCropper(cropper);
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          {
            <Label
              className="btn btn-primary"
              htmlFor={`inputImage ${props.tempId ?? ""}`}
            >
              <input
                type="file"
                className="sr-only"
                id={`inputImage ${props.tempId ?? ""}`}
                name="file"
                accept="image/*"
                onChange={onChange}
              />
              Change
            </Label>
          }
          {shouldShowDoneButton && (
            <Button color="primary" onClick={handleDoneButton}>
              Done
            </Button>
          )}{" "}
          <Button color="secondary" onClick={togglePopup}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UpdatePhoto;

import React from "react";
import { Redirect } from "react-router-dom";







import EditEnquiryForm from "../pages/Enquiry/EditEnquiryForm";

import EditAdmissionForm from "../pages/Admissions/EditAdmissionForm";




import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

import SocialMedia from "../pages/Utility/socialMedia";







//Franchise

import FranchiseEnquiry from "../pages/Franchise/enquiry";




const userRoutes = [
  

  

 
  



  

  

  

  

  

  

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => (
      <Redirect
        to={"/pages-404"}
      />
    ),
  },
];

const authRoutes = [
  

  
  

  { path: "/enquiry-form/:id", component: EditEnquiryForm },

  {
    path: "/enq-form/:client/:schoolId/:id",
    component: EditEnquiryForm,
  },


  { path: "/admission-form/:schoolId/:id", component: EditAdmissionForm },
  { path: "/admission-form/:schoolId/:kidId/:id", component: EditAdmissionForm },
  {
    path: "/franchise-admission-form/:franchiseId/:schoolId/:id",
    component: EditAdmissionForm,
  },
  { path: "/franchise-admission-form/:franchiseId/:schoolId/:kidId/:id", component: EditAdmissionForm },

  

  { path: "/franchise/enquiry/:id", component: FranchiseEnquiry },
  { path: "/institute/enquiry/:id", component: FranchiseEnquiry },
  {
    path: "/franchise-enquiry-form/:id/:franchiseId",
    component: EditEnquiryForm,
  },
  {
    path: "/institute-enquiry-form/:id/:instituteId",
    component: EditEnquiryForm,
  },

  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  { path: "/social-media/:id", component: SocialMedia },
  

  

];

export { userRoutes, authRoutes };

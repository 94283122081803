import Parse from "parse";
const getSchoolFromParse = (schoolId, isLocal) => {
  try {
    return new Promise((resolve, reject) => {
      var School = Parse.Object.extend("School");
      var query = new Parse.Query(School);
      if (isLocal) {
        query.fromLocalDatastore();
      }
      query.get(schoolId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

const deleteSchoolFromParse = (schoolId) => {
  try {
    return new Promise((resolve, reject) => {
      const School = Parse.Object.extend("School");
      var school = new School();
      school.id = schoolId;
      school.set("deleted", true);
      if (Parse.User.current().attributes.teacherId) {
        school.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        school.set("deletedBy", Parse.User.current().id);
      }

      school.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

const getSchoolRatingsForSchoolId = (schoolId, updatedDate, isLoacal) => {
  // try {

  //   return new Promise((resolve, reject) => {
  //     var ratingParamsQueryObj = Parse.Object.extend("SchoolRatings");
  //     var query = new Parse.Query(ratingParamsQueryObj
  //     );
  //     query.equalTo("schoolId", schoolId);
  //     query.find().then(
  //       (result) => {
  //         resolve(result);
  //       },
  //       (error) => {
  //         resolve(error);
  //       }
  //     );
  //   });
  // } catch (error) { }

  try {
    return new Promise((resolve, reject) => {
      var schoolRatingQueryObj = Parse.Object.extend("SchoolRatings");
      var mainQuery = new Parse.Query(schoolRatingQueryObj);
      mainQuery.equalTo("schoolId", schoolId);

      if (updatedDate) {
        mainQuery.greaterThanOrEqualTo("updatedAt", updatedDate);
      }

      if (isLoacal) {
        mainQuery.fromLocalDatastore();
      }

      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

const getRatingParamsForSchoolId = (schoolObject) => {
  try {
    return new Promise((resolve, reject) => {
      var ratingParamsQueryObj = Parse.Object.extend("SchoolRatingParams");
      var query = new Parse.Query(ratingParamsQueryObj);
      query.equalTo("schoolType", schoolObject.attributes.schoolType);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

const getPaymentOptionsForSchoolId = (schoolId) => {
  try {
    return new Promise((resolve, reject) => {
      var paymentOptions = Parse.Object.extend("PaymentOptions");
      var query = new Parse.Query(paymentOptions);
      query.equalTo("schoolId", schoolId);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  } catch (error) {}
};
const editPaymentOptions = (paymentOptionsForSchool) => {
  try {
    return new Promise((resolve, reject) => {
      paymentOptionsForSchool.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

const editSchool = (school) => {
  try {
    return new Promise((resolve, reject) => {
      school.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

export const getAllSchoolsForInstitute = (instituteId) => {
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("School");
      query.equalTo("instituteId", instituteId);
      query.notEqualTo("deleted", true);
      query.limit(1000);
      query.find().then((schoolObjs) => {
        resolve(schoolObjs);
      });
    });
  } catch (error) {}
};

const getSchoolsForInstitute = (id, type, isLoacal) => {
  try {
    return new Promise((resolve, reject) => {
      var School = Parse.Object.extend("School");
      var mainQuery = new Parse.Query(School);
      mainQuery.equalTo(type, id);
      mainQuery.limit(1000);
      if (isLoacal) {
        mainQuery.fromLocalDatastore();
      }
      mainQuery.notEqualTo("deleted", true);

      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

export const getFranchiseDetails = (franchiseId) => {
  return new Promise((resolve, reject) => {
    let query = new Parse.Query("Franchise");
    query.equalTo("objectId", franchiseId);
    query.first().then((result) => {
      resolve(result);
    });
  });
};

export const getAllSchoolForFranchise = (franchiseId) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run("getAllSchoolsForFranchise", {
      franchiseId: franchiseId,
    }).then((results) => {
      resolve(results);
    });
  });
};

export const getAllSchoolForInstitute = (instituteId) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run("function_getAllSchoolForInstitute", {
      instituteId: instituteId,
    }).then((results) => {
      resolve(results);
    });
  });
};

export const getInstituteDeatilsForId = (instituteId) => {
  return new Promise((resolve, reject) => {
    // let query = new Parse.Query("Institute");
    // query.equalTo("objectId", instituteId);
    // query.first().then((result) => {
    //   resolve(result);
    // });

    Parse.Cloud.run("function_getInstituteForId", {
      instituteId: instituteId,
    }).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        console.log("error" + error.message);
      }
    );
  });
};

export {
  getSchoolFromParse,
  deleteSchoolFromParse,
  editSchool,
  getPaymentOptionsForSchoolId,
  editPaymentOptions,
  getRatingParamsForSchoolId,
  getSchoolRatingsForSchoolId,
  getSchoolsForInstitute,
};

import React from 'react';
import logo from "../../assets/images/lilLogo.png"; 
import logoWithName from "../../assets/images/logo-dark.png"; 
import adigurukulName2 from "../../assets/images/adigurukul/adigurukul2.png";


export const getAppName = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return "Adigurukul";
    }
    return "lilTriangle";
};
export const getAppLogo = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return adigurukulName2;
    }
    return logo;
};
export const getAppLogoHeight = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return "70";
    }
    //35
    return "35";
};

export const getAppLogoWithName = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return adigurukulName2;
    }
    return logoWithName;
};
export const getAppLogoWithNameHeight = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return "70";
    }
    //30
    return "30";
};

export const getFooterName = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return "Innomonk Technologies";
    }
    return "lilTriangle";
};

export const getDesignTag = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return "learning for all";
    }
    return (<div>Crafted with{" "}<i className="mdi mdi-heart text-danger"></i> by lilTriangle</div>);
};
export const getHost = () => {
    var currentLocation = window.location;
    if (isAdigurkul(currentLocation)) {
        return "https://adigurukul.innomonk.com";
    } else {
        return "https://liltriangleweb.web.app"
    }
};

export const isAdigurkulApp = () => {
    var currentLocation = window.location;
    return isAdigurkul(currentLocation);
};

let isAdigurkul = (currentLocation) => {
    if (currentLocation.host === "adigurukul.innomonk.com") {
        return true;
    }else if(currentLocation.hostname.toLowerCase().startsWith("adigurukul")){
        return true;
    }
    if (currentLocation.ancestorOrigins) {
        var link = currentLocation.ancestorOrigins[0];
        if (link && link.includes("adigurukul")) {
            return true;
        }
        return false;
    } else {
        return false
    }
}

import React,{ useEffect, useState } from 'react';
import { Container,Row,Col } from "reactstrap";
import { matchPath } from "react-router";

const SocialMedia = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/social-media/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.id;

    const logo="https://parsefiles.back4app.com/n1BIxpDhrdEUUwxqMla37DBZoMlFIWLRVLVKXK0a/90be35e7e87d5727df3bf67be4c24a6b_Screenshot%2020240406%20at%2011.45.18%20PM.png"

    const location = "https://maps.app.goo.gl/MKH8UAMbBUVQxjFHA"
    
    const facebook = "https://www.facebook.com/KidzeePunjabiBagh/"
    const insta = "https://www.instagram.com/kidzee_punjabibagh/"
    
    const EnquiryLink = "https://forms.liltriangle.com/enquiry-form/PBe3IjTXh2"
    const website = "https://kidzeepunjabibagh.com/"
    
    useEffect(() => {
        if (schoolId) {
            
        }
    }, [schoolId]);

    return (
        <React.Fragment >
          
            {schoolId == "PBe3IjTXh2" && <Container fluid  he > 

            <Row>
                <Col>
                    
                    <img
                        className='rounded'
                    />

                        <div className="card text-center team-box">
                            <div className="card-body">
                                <div>
                                    <img src={logo} alt="" className="avatar-md" />
                                </div>

                                <div className="mt-3">
                                    <h5>KIDZEE PUNJABI BAGH</h5>
                                </div>
                            </div>
                        </div>
                
                </Col>
            </Row>

            <Row>
                

                <Col lg={12}>
                    
                    
                    <div className="card text-center team-box">
                        <div className="card-body">
                            <div>
                                <h3>
                                    <a href={facebook} target='_blank'>
                                        <i className="m-2 bx bxl-facebook-circle" ></i>
                                        Facebook
                                    </a>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="card text-center team-box">
                        <div className="card-body">
                            <div>
                                <h3>
                                    <a href={insta} target='_blank'>
                                        <i className="m-2 bx bxl-instagram-alt" ></i>
                                        Instagram
                                    </a>
                                </h3>
                            </div>
                        </div>
                    </div>


                    {/* <div className="card text-center team-box">
                        <div className="card-body">
                            <div>
                                <h3>
                                    
                                    <a href={location} target='_blank'>
                                        <i className="m-2 bx bx-map-pin" ></i>
                                        Location
                                    </a>
                                </h3>
                            </div>
                        </div>
                    </div> */}


                    <div className="card text-center team-box">
                        <div className="card-body">
                                <div>
                                <h3>
                                    
                                    <a href={EnquiryLink} target='_blank'>
                                        <i className="m-2 bx bx-clipboard" ></i>
                                        Enquiry Form
                                    </a>
                                </h3>
                            </div>
                        </div>
                    </div>
                        
                    <div className="card text-center team-box">
                        <div className="card-body">
                            <div>
                                <h3>
                                    
                                    <a href={website} target='_blank'>
                                        <i className="m-2 bx bx-map-pin" ></i>
                                        Website
                                    </a>
                                </h3>
                            </div>
                        </div>
                    </div>
                </Col>
                
            </Row>

            


            </Container>}

            {schoolId!="PBe3IjTXh2" && <p className='m-4 text center'>Coming soon</p>}
            
        </React.Fragment>
            
    );
}
            
export default SocialMedia;
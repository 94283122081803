export const DELETE_SCHOOL = "DELETE_SCHOOL";
export const GET_SCHOOL = "GET_SCHOOL";
export const EDIT_SCHOOL = "EDIT_SCHOOL";
export const UPDATE_SCHOOL = "UPDATE_SCHOOL";
export const GET_PAYMENT_OPTIONS_FOR_SCHOOL = "GET_PAYMENT_OPTIONS_FOR_SCHOOL";
export const UPDATE_PAYMENT_OPTIONS = "UPDATE_PAYMENT_OPTIONS";
export const EDIT_PAYMENT_OPTIONS = "EDIT_PAYMENT_OPTIONS";

export const UPDATE_SELECTED_SCHOOL = "UPDATE_SELECTED_SCHOOL";
export const SET_SELECTED_SCHOOL = "SET_SELECTED_SCHOOL";

export const SCHOOL_SUCCESS = "SCHOOL_SUCCESS";
export const SCHOOL_ERROR = "SCHOOL_ERROR";
export const GET_ALL_SCHOOLS_FOR_INSTITUTE = "GET_ALL_SCHOOLS_FOR_INSTITUTE";
export const SET_ALL_SCHOOLS_FOR_INSTITUTE = "SET_ALL_SCHOOLS_FOR_INSTITUTE";

export const GET_SCHOOLS_FOR_INSTITUTE = "GET_SCHOOLS_FOR_INSTITUTE";
export const UPDATE_SCHOOLS = "UPDATE_SCHOOLS";
export const SWITCH_SCHOOL = " SWITCH_SCHOOL";

//SCHOOL RATINGS
export const GET_RATING_PARAMS = "GET_RATING_PARAMS";
export const UPDATE_RATING_PARAMS = "UPDATE_RATING_PARAMS";
export const GET_SCHOOL_RATINGS = "GET_SCHOOL_RATINGS";
export const UPDATE_SCHOOL_RATINGS = "UPDATE_SCHOOL_RATINGS";

//franchise
export const GET_FRANCHISE_DETAILS = "GET_FRANCHISE_DETAILS";
export const SET_FRANCHISE_DETAILS = "SET_FRANCHISE_DETAILS";
export const GET_ALL_SCHOOL_DETAILS_FOR_FRANCHISE =
  "GET_ALL_SCHOOL_DETAILS_FOR_FRANCHISE";
export const SET_ALL_SCHOOL_DETAILS_FOR_FRANCHISE =
  "SET_ALL_SCHOOL_DETAILS_FOR_FRANCHISE";

//Institute
export const GET_INSTITUTE_DETAILS_FOR_ID = "GET_INSTITUTE_DETAILS_FOR_ID";
export const SET_INSTITUTE_DETAILS_FOR_ID = "SET_INSTITUTE_DETAILS_FOR_ID";
export const GET_ALL_SCHOOL_DETAILS_FOR_INSTITUTE =
  "GET_ALL_SCHOOL_DETAILS_FOR_INSTITUTE";

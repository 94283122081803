import { combineReducers } from "redux";


import School from "./model/school/reducer";




const appReducer = combineReducers({
  // public
 
  School,
 
  
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER" || action.type === "SWITCH_SCHOOL") {
    const { routing } = state;
    state = { routing };
  }

  return appReducer(state, action);
};

export default rootReducer;

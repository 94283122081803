import {
  takeEvery,
  fork,
  put,
  all,
  call,
  takeLeading,
} from "redux-saga/effects";
import * as SchoolHelper from "../../../helpers/ParseHelpers/school_helper";
import * as actionTypes from "./actionTypes";
import * as SchoolActions from "./actions";
import Parse from "parse";
import * as Constants from "../../../Constents";
import actions from "redux-form/lib/actions";

function* getASchool({ payload: { id, isUpdate } }) {
  try {
    if (id) {
      const response = yield call(SchoolHelper.getSchoolFromParse, id, false);
      if (response) {
        if (isUpdate) {
          yield put(SchoolActions.updateSchool(response));
        }
        response.pin();
      }
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteASchool({ payload: { schoolId } }) {
  try {
    const response = yield call(SchoolHelper.deleteSchoolFromParse, schoolId);
    if (response) {
      yield put(SchoolActions.deleteSchool(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* setSelectedSchool({ payload: { id } }) {
  try {
    if (id) {
      const localResponse = yield call(
        SchoolHelper.getSchoolFromParse,
        id,
        true
      );
      if (localResponse) {
        yield put(SchoolActions.updateSelecetdSchool(localResponse));
      }

      const serverResponse = yield call(
        SchoolHelper.getSchoolFromParse,
        id,
        false
      );
      if (serverResponse) {
        yield put(SchoolActions.updateSelecetdSchool(serverResponse));
        serverResponse.pin();
      }
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* editSchool({ payload: { school, moveBack } }) {
  try {
    const response = yield call(SchoolHelper.editSchool, school);
    if (response) {
      yield put(SchoolActions.updateSchool(response));
      if (moveBack) {
        window.history.back();
      }
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getPaymentOptionsForSchoolId({ payload: { id } }) {
  try {
    const response = yield call(SchoolHelper.getPaymentOptionsForSchoolId, id);
    if (response) {
      yield put(SchoolActions.updatePaymentOptions(response));
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* editPaymentOptions({ payload: { paymentOptionsForSchool } }) {
  try {
    const response = yield call(
      SchoolHelper.editPaymentOptions,
      paymentOptionsForSchool
    );
    if (response) {
      yield put(SchoolActions.updatePaymentOptions(response));
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

//School Ratings
function* getRatingParamsForSchoolId({ payload: { schoolObject } }) {
  try {
    const response = yield call(
      SchoolHelper.getRatingParamsForSchoolId,
      schoolObject
    );
    if (response) {
      yield put(SchoolActions.updateRatingParams(response));
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getSchoolRatingsForSchoolId({ payload: { id } }) {
  // try {
  //     const response = yield call(SchoolHelper.getSchoolRatingsForSchoolId, id);
  //     if (response) {
  //         yield put(SchoolActions.updateSchoolRatings(response));
  //     } else {

  //     }
  // } catch (error) {
  //     // yield put(apiError(error));
  // }
  try {
    var key = Constants.SCHOOL_RATING_UPDATED_TIME + "_" + id;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      SchoolHelper.getSchoolRatingsForSchoolId,
      id,
      null,
      true
    );
    yield put(SchoolActions.updateSchoolRatings(localResponse));

    const serverResponse = yield call(
      SchoolHelper.getSchoolRatingsForSchoolId,
      id,
      updatedTime,
      false
    );
    localStorage.setItem(key, newTime);

    if (serverResponse && serverResponse.length > 0) {
      yield put(SchoolActions.updateSchoolRatings(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* getAllScholForInstitute({ payload: { instituteId } }) {
  try {
    const response = yield call(
      SchoolHelper.getAllSchoolsForInstitute,
      instituteId
    );
    // yield put(SchoolActions.setAllSchoolsForInstitute(response))
  } catch (error) {}
}

function* getSchoolsForInstutute({ payload: { id, type } }) {
  try {
    const localResponse = yield call(
      SchoolHelper.getSchoolsForInstitute,
      id,
      type,
      true
    );
    yield put(SchoolActions.updateSchools(localResponse));

    var update = false;
    // if (localResponse && localResponse.length > 0) {
    //   var school = setSechoolToLocal(localResponse);
    //   if (school) {
    //     yield put(SchoolActions.updateSelecetdSchool(school));
    //     update = true;
    //   }
    // }

    const serverResponse = yield call(
      SchoolHelper.getSchoolsForInstitute,
      id,
      type,
      false
    );

    if (serverResponse && serverResponse.length > 0) {
      yield put(SchoolActions.updateSchools(serverResponse));
      Parse.Object.pinAll(serverResponse);
      // if (!update) {
      //   var school = setSechoolToLocal(serverResponse);
      //   if (school) {
      //     yield put(SchoolActions.updateSelecetdSchool(school));
      //   }
      // }
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function setSechoolToLocal(newList) {
  if (newList.length > 0) {
    var schoolId = localStorage.getItem(Constants.SELECTED_SCHOOL_ID);
    if (schoolId) {
      var temp = newList.filter((school) => school.id === schoolId);
      if (temp && temp.length > 0) {
        return temp[0];
      } else {
        localStorage.setItem(Constants.SELECTED_SCHOOL_ID, newList[0].id);
        return newList[0];
      }
    } else {
      localStorage.setItem(Constants.SELECTED_SCHOOL_ID, newList[0].id);
      return newList[0];
    }
  }
}

function* switchSchool({ payload: { school } }) {
  try {
    // yield put(SchoolActions.updateSelecetdSchool(school));
    // localStorage.setItem(Constants.SELECTED_SCHOOL_ID, school.id)
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getFranchiseDetails({ payload: { franchiseId } }) {
  try {
    let response = yield call(SchoolHelper.getFranchiseDetails, franchiseId);
    yield put(SchoolActions.setFranchiseDetails(response));
  } catch (err) {
    console.log(err);
  }
}
function* getAllSchoolDetailsForFranchise({ payload: { franchiseId } }) {
  try {
    let response = yield call(
      SchoolHelper.getAllSchoolForFranchise,
      franchiseId
    );
    yield put(SchoolActions.setAllSchoolDetailsForFranchise(response));
  } catch (err) {
    console.log(err);
  }
}
function* getAllSchoolDetailsForInstitute({ payload: { instituteId } }) {
  try {
    let response = yield call(
      SchoolHelper.getAllSchoolForInstitute,
      instituteId
    );
    yield put(SchoolActions.setAllSchoolDetailsForFranchise(response));
  } catch (err) {
    console.log(err);
  }
}

function* getInstituteDeatilsForId({ payload: { instituteId } }) {
  try {
    let response = yield call(
      SchoolHelper.getInstituteDeatilsForId,
      instituteId
    );
    yield put(SchoolActions.setInstituteDetailsForId(response));
  } catch (err) {
    console.log(err);
  }
}

export function* watchGetSchoolRatingsForSchoolId() {
  yield takeEvery(actionTypes.GET_SCHOOL_RATINGS, getSchoolRatingsForSchoolId);
}

export function* watchGetRatingParamsForSchoolId() {
  yield takeEvery(actionTypes.GET_RATING_PARAMS, getRatingParamsForSchoolId);
}

export function* watchEditPaymentOptions() {
  yield takeEvery(actionTypes.EDIT_PAYMENT_OPTIONS, editPaymentOptions);
}

export function* watchGetPaymentOptionsForSchool() {
  yield takeEvery(
    actionTypes.GET_PAYMENT_OPTIONS_FOR_SCHOOL,
    getPaymentOptionsForSchoolId
  );
}

export function* watchGetSchool() {
  yield takeEvery(actionTypes.GET_SCHOOL, getASchool);
}

export function* watchDeleteSchool() {
  yield takeEvery(actionTypes.DELETE_SCHOOL, deleteASchool);
}

export function* watchSetSelectedSchool() {
  yield takeEvery(actionTypes.SET_SELECTED_SCHOOL, setSelectedSchool);
}

export function* watchEditSchool() {
  yield takeEvery(actionTypes.EDIT_SCHOOL, editSchool);
}
export function* watchGetAllSchools() {
  yield takeEvery(
    actionTypes.GET_ALL_SCHOOLS_FOR_INSTITUTE,
    getAllScholForInstitute
  );
}

export function* watchGetSchoolsForInstitute() {
  yield takeLeading(
    actionTypes.GET_SCHOOLS_FOR_INSTITUTE,
    getSchoolsForInstutute
  );
}

export function* watchSwitchSchool() {
  yield takeLeading(actionTypes.SWITCH_SCHOOL, switchSchool);
}
export function* watchGetFranchiseDetails() {
  yield takeEvery(actionTypes.GET_FRANCHISE_DETAILS, getFranchiseDetails);
}
export function* watchGetAllSchoolForFranchise() {
  yield takeEvery(
    actionTypes.GET_ALL_SCHOOL_DETAILS_FOR_FRANCHISE,
    getAllSchoolDetailsForFranchise
  );
}
export function* watchGetAllSchoolForInstitute() {
  yield takeEvery(
    actionTypes.GET_ALL_SCHOOL_DETAILS_FOR_INSTITUTE,
    getAllSchoolDetailsForInstitute
  );
}
export function* watchGetInstituteDetailsForId() {
  yield takeEvery(
    actionTypes.GET_INSTITUTE_DETAILS_FOR_ID,
    getInstituteDeatilsForId
  );
}
function* schoolSaga() {
  yield all([
    fork(watchGetSchoolRatingsForSchoolId),
    fork(watchGetRatingParamsForSchoolId),
    fork(watchEditPaymentOptions),
    fork(watchGetPaymentOptionsForSchool),
    fork(watchGetSchool),
    fork(watchDeleteSchool),
    fork(watchSetSelectedSchool),
    fork(watchEditSchool),
    fork(watchGetAllSchools),
    fork(watchGetSchoolsForInstitute),
    fork(watchSwitchSchool),
    fork(watchGetFranchiseDetails),
    fork(watchGetAllSchoolForFranchise),
    fork(watchGetInstituteDetailsForId),
    fork(watchGetAllSchoolForInstitute),
  ]);
}
export default schoolSaga;
